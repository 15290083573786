<template>
  <div id="expanded-entity-overview__widgets">
    <b-tabs
      v-if="tabs.length"
      class="tabs--inner"
      pills
      :value="tabValue"
      @input="handleTabChange"
    >
      <b-tab
        v-for="(item, i) in tabs"
        :key="i"
        :title="$t(item.label)"
      >
        <b-card no-body>
          <div v-if="item.entityId === activeTab">
            <SidebarForm
              :resource-id="resourceId"
              :visible="showForm"
              :row-order="items.length"
              :schema-key="item.schemaKey"
              :table-key="item.tableKey"
              @close="showForm = false"
              @saved="getWidgets()"
            />
            <div class="m-2 d-flex justify-content-between table-actions">
              <div class="d-flex">
                <div class="mr-1">
                  <b-button
                    :id="`create-${item.entityId}-button`"
                    variant="primary"
                    :class="{
                      'icon-disabled': !canCreate,
                    }"
                    @click="canCreate && toggleModal()"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Create New') }}</span>
                  </b-button>
                  <b-tooltip :target="`create-${item.entityId}-button`">
                    {{ canCreate ? $t('Create') : $t('no-action-permission') }}
                  </b-tooltip>
                  <b-button
                    :id="`add-${item.entityId}-widget-button`"
                    variant="primary"
                    style="margin-left: 8px;"
                    @click="openWidgetModal()"
                    v-if="activeTab === 'crm.company' || activeTab === 'crm.contact'"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Add Widget') }}</span>
                  </b-button>
                </div>
              </div>
            </div>
            <div class="m-2">
              <b-table-simple
                responsive
                class="widgets__table"
              >
                <b-thead>
                  <b-tr>
                    <b-th
                      v-for="(header, headerIndex) in tableColumns"
                      :key="headerIndex"
                      :class="header.class"
                    >
                      {{ $t(header.label) }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <Draggable
                  tag="b-tbody"
                  :list="items"
                  ghost-class="ghost"
                  :animation="150"
                  handle=".pz__drag-handle"
                  @start="onStartRowMove"
                  @sort="handleSort"
                >
                  <b-tr v-if="loading">
                    <b-td
                      colspan="100%"
                      class="text-center"
                    >
                      <b-spinner class="align-middle" />
                    </b-td>
                  </b-tr>
                  <b-tr v-else-if="!items.length">
                    <b-td
                      colspan="100%"
                      class="text-center"
                    >
                      {{ $t('No matching records found') }}
                    </b-td>
                  </b-tr>
                  <b-tr
                    v-for="(widget, widgetIndex) in items"
                    :key="widgetIndex"
                    draggable="true"
                    class="widgets__row"
                    :class="{ 'widgets__row--hidden': !widget.visible }"
                  >
                    <b-td style="width: 50px">
                      <ProzessDragHandle class="tw-mr-2" />
                    </b-td>
                    <b-td style="width: 50px">
                      {{ widgetIndex + 1 }}
                    </b-td>
                    <b-td class="text-nowrap">
                      <feather-icon
                        :icon="widget.icon"
                        size="16"
                        class="text-secondary tw-mr-2"
                      />
                      {{ widget.name }}
                    </b-td>
                    <b-td class="text-nowrap">
                      <b-badge
                        v-for="(field, fieldIndex) in getFieldsPreview(widget.fields)"
                        :key="fieldIndex"
                        variant="secondary"
                        class="tw-mr-2"
                      >
                        {{ $t(field) }}
                      </b-badge>
                    </b-td>
                    <!-- START: Actions -->
                    <b-td class="text-center text-nowrap">
                      <feather-icon
                        :id="`toggle-visibility-${widget.widgetUuid}`"
                        :icon="widget.visible ? 'EyeIcon' : 'EyeOffIcon'"
                        size="16"
                        class="mx-1 cursor-pointer"
                        :class="{ 'icon-disabled': !canUpdate }"
                        @click="canUpdate && toggleVisibility(widget)"
                      />
                      <b-tooltip
                        :target="`toggle-visibility-${widget.widgetUuid}`"
                        placement="left"
                      >
                        {{ canUpdate ? $t(widget.visible ? 'Hide' : 'Show') : $t('no-action-permission') }}
                      </b-tooltip>
                      <feather-icon
                        :id="`edit-${widget.widgetUuid}`"
                        icon="EditIcon"
                        size="16"
                        class="mx-1 cursor-pointer"
                        :class="{ 'icon-disabled': !canUpdate }"
                        @click="canUpdate && toggleModal(widget.widgetUuid)"
                      />
                      <b-tooltip
                        :target="`edit-${widget.widgetUuid}`"
                        placement="left"
                      >
                        {{ canUpdate ? $t('Edit') : $t('no-action-permission') }}
                      </b-tooltip>
                      <feather-icon
                        :id="`delete-${widget.widgetUuid}`"
                        icon="TrashIcon"
                        size="16"
                        class="mx-1 cursor-pointer"
                        :class="{ 'icon-disabled': !canDelete }"
                        @click="canDelete && confirmRemoval(widget)"
                      />
                      <b-tooltip
                        :target="`delete-${widget.widgetUuid}`"
                        placement="left"
                      >
                        {{ canDelete ? $t('Delete') : $t('no-action-permission') }}
                      </b-tooltip>
                    </b-td>
                    <!-- END: Actions -->
                  </b-tr>
                </Draggable>
              </b-table-simple>
            </div>
          </div>
        </b-card>
      </b-tab>
    </b-tabs>
    <AddWidgetModal ref="widgetModal" :items=this.items @saved="getWidgets()"/>
  </div>
</template>

<script>
import { Entity, GenericTabs, Schema } from '@/constants/app'
import WebhookService from '@/services/webhooks'
import SidebarForm from '@/views/pages/SystemSettings/ExpandedEntityOverview/Form.vue'
import expandedEntityOverviewService from '@/services/expandedEntityOverview'
import ProzessDragHandle from '@/components/shared/ProzessDragHandle.vue'
import Draggable from 'vuedraggable'
import { swalConfirmDeleteOption } from '@/helpers/app'
import AddWidgetModal from './AddWidgetModal.vue'

export default {
  name: 'ExpandedEntityOverviewWidgets',

  components: {
    Draggable,
    ProzessDragHandle,
    SidebarForm,
    AddWidgetModal,
  },

  data() {
    return {
      Entity,
      entities: [],
      tabs: [],
      defaultTab: `${Schema.CRM}.${Entity.COMPANY}`,
      resourceId: null,
      showForm: false,

      loading: false,
      items: [],
      tableColumns: [
        { key: 'draghandle', label: ' ', class: 'disabled' },
        { key: 'order', label: 'Order', class: 'disabled' },
        { key: 'name', label: 'Widget Name', class: 'disabled' },
        { key: 'fields', label: 'Fields', class: 'disabled' },
        { key: 'actions', label: 'Actions', class: 'text-center disabled' },
      ],

      sorting: false,
      sortingTimeout: null,
    }
  },

  computed: {
    canCreate() {
      return this.$can('Update', 'System_Settings')
    },

    canUpdate() {
      return this.$can('Update', 'System_Settings')
    },

    canDelete() {
      return this.$can('Update', 'System_Settings')
    },

    tabValue() {
      return this.tabs.findIndex(({ entityId }) => entityId === this.$route.params.tab)
    },

    activeTab() {
      return this.$route.params.tab || this.defaultTab
    },

    entity() {
      const [schemaKey, tableKey] = this.activeTab.split('.')
      return { schemaKey, tableKey }
    },
  },

  watch: {
    '$route.params': {
      handler(params) {
        if (params.tab === this.activeTab) {
          this.getWidgets()
        }
      },

      immediate: true,
    },
  },

  async mounted() {
    await this.getEntities()
    await this.setupTabs()
  },

  methods: {
    openWidgetModal() {
      this.$refs.widgetModal.openModal({
        open: true,
        widgetUuid: '',
      })
    },
    confirmRemoval(item) {
      if (!this.canDelete) {
        return
      }

      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(async result => {
        if (result.value) {
          await this.$async(expandedEntityOverviewService.delete(item.widgetUuid))

          this.getWidgets()
        }
      })
    },

    async getEntities() {
      const { response } = await this.$async(WebhookService.getAllEntities())
      this.entities = response.data
    },

    getFieldsPreview(fields) {
      const truncatedFields = fields.filter((f, i) => i < 2).map(f => f.fieldLabel)

      const remaining = fields.length - truncatedFields.length

      return remaining ? [...truncatedFields, `+${remaining}`] : truncatedFields
    },

    async getWidgets() {
      this.loading = true

      const { response } = await this.$async(expandedEntityOverviewService.getAll({ ...this.entity }))

      this.items = response.data
      this.loading = false
    },
    arrayMove(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          let k = new_index - arr.length + 1
          while (k--) {
              arr.push(undefined)
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
      return arr
    },
    onStartRowMove(ev) {
      this.originalItems = JSON.parse(JSON.stringify(this.items))
    },
    handleSort(ex) {
      const { entity } = this
      this.items = JSON.parse(JSON.stringify(this.originalItems))
      this.items = this.items.filter(i => i != null)
      const newItems = JSON.parse(JSON.stringify(this.items))
      this.arrayMove(newItems, ex.oldIndex, ex.newIndex)
      this.items = newItems
      this.doDebounce(
        'sortingTimeout',
        () => {
          expandedEntityOverviewService.reorder(
            { ...entity },
            this.items.map((widget, i) => ({
              widgetUuid: widget.widgetUuid,
              rowOrder: i,
            })),
          )
        },
        100,
      )
    },

    handleTabChange(index) {
      this.$router.push({
        name: GenericTabs.EXPANDED_ENTITY_OVERVIEW,
        params: {
          tab: this.tabs[index].entityId || this.defaultTab,
        },
      })
    },

    setupTabs() {
      this.tabs = this.$lodash
        .orderBy(this.entities, [entity => entity.label.toLowerCase()])
        .filter(
          ({ schemaKey, tableKey }) =>
            schemaKey !== Schema.CUSTOM_RELATIONSHIP && ![Entity.NOTE_ATTACHED_TO, Entity.COMPANY_HAS_CONTACT, Entity.DOCUMENT].includes(tableKey),
        )
        .map(entity => ({
          ...entity,
          customEntity: entity.schemaKey === Schema.CUSTOM,
          entityId: `${entity.schemaKey}.${entity.tableKey}`,
        }))
        .sort((a, b) => a.customEntity - b.customEntity)
    },
    toggleModal(resourceId = null) {
      this.resourceId = resourceId
      this.showForm = true
    },

    async toggleVisibility(widget) {
      await this.$async(
        expandedEntityOverviewService.toggleVisibility(widget.widgetUuid, {
          visible: !widget.visible,
        }),
      )

      this.getWidgets()
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/master-variables.scss';
#expanded-entity-overview__widgets {
  .widgets__table {
    .widgets__row {
      &.ghost,
      &.sortable-chosen {
        background: darken($colour--table-row, 8%);
      }
    }

    .widgets__row--hidden td {
      background: darken($colour--table-row, 4%);
    }
  }
}
</style>
