<template>
  <div
    class="tw-rounded-lg tw-mt-4"
    style="background: var(--colour--main-400)"
  >
    <p class="tw-text-text-primary tw-text-sm tw-px-4 tw-py-4 mb-0">
      {{ $t('Fields') }}
    </p>
    <ul class="tw-list-none p-0">
      <draggable
        :list="list"
        ghost-class="ghost"
        :animation="150"
        handle=".pz__drag-handle"
      >
        <li
          v-for="(item, i) in list"
          :key="i"
          class="mx-0 tw-p-4 tw-flex tw-items-center tw-justify-between border-bottom"
          :class="[{ 'border-bottom-0': i === list.length - 1 }]"
        >
          <span>
            <ProzessDragHandle class="tw-mr-2" />
            {{ $t(item.label) }}
          </span>
          <feather-icon
            icon="XIcon"
            class="text-white tw-cursor-pointer bg-primary tw-rounded-full tw-flex tw-justify-center tw-items-center"
            style="padding: 2px"
            size="16"
            @click="$emit('remove', item)"
          />
        </li>
      </draggable>
    </ul>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import ProzessDragHandle from '@/components/shared/ProzessDragHandle.vue'

export default {
  components: {
    Draggable,
    ProzessDragHandle,
  },

  props: {
    list: {
      type: Array,
      required: true,
    },
  },
}
</script>
