import * as yup from 'yup'

export const widgetSchema = yup.object().shape(
  {
    name: yup.string().nullable().required().max(50, 'Max 50 limit'),
    icon: yup.string().nullable().required().max(30, 'Max 30 limit'),
    fields: yup.array().min(1, 'Min 1 limit'),
  },
)

export default {
  widgetSchema,
}
