const getRowOrder = (selectedFields, field) => selectedFields.findIndex(
  ({ fieldKey }) => fieldKey === field.fieldKey,
)

export const mapFieldForPayload = (selectedFields, field, entity, rowOrder = null) => ({
  fieldKey: field.fieldKey,
  customField: field.customField,
  fieldLabel: field.label,
  fieldType: field.type,
  schemaKey: entity.schemaKey,
  tableKey: entity.tableKey,
  rowOrder: rowOrder ?? getRowOrder(selectedFields, field),
})

export const formatUpdatedFields = (selectedFields, lastSavedFields, entity) => {
  const newFields = []
  const updatedFields = []

  const lastSavedKeys = lastSavedFields.map(({ fieldKey }) => fieldKey)

  selectedFields.forEach(field => {
    const partOfLastSaved = lastSavedKeys.includes(field.fieldKey)
    partOfLastSaved ? updatedFields.push(field) : newFields.push(field)
  })

  const deletedFields = lastSavedKeys.filter(
    id => !selectedFields.some(({ fieldKey }) => id === fieldKey),
  )

  return {
    newFields: newFields.map(field => mapFieldForPayload(selectedFields, field, entity)),

    updatedFields: updatedFields.map(field => ({
      oldFieldKey: field.fieldKey,
      newValuesField: mapFieldForPayload(selectedFields, field, entity),
    })),

    deletedFields: deletedFields.map(fieldKey => ({ fieldKey })),
  }
}
