<template>
  <prozess-field-wrapper
    class="flex"
  >
    <prozess-select
      ref="control"
      class="w-100 expanded-entity-overview__field-selector"
      label="label"
      :placeholder="$t(placeholder)"
      :options="options"
      :append-to-body="false"
      :clearable="false"
      :hint="hint"
      :error="error"
      @keyup.enter.native="$emit('enter')"
      @input="handleInput"
    >
      <template #selected-option>
        <span class="text-muted">
          {{ $t(placeholder) }}
        </span>
      </template>
      <template #option="{ label }">
        {{ $t(label) }}
      </template>
    </prozess-select>
  </prozess-field-wrapper>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Select',
    },

    options: {
      type: Array,
      default: () => [],
    },

    hint: {
      type: String,
      default: null,
    },

    error: {
      type: String,
      default: null,
    },
  },

  methods: {
    handleInput(e) {
      this.$refs.control.currentValue = null
      this.$emit('select', e)
    },
  },
}
</script>

<style lang="scss">
.expanded-entity-overview__field-selector {
  .v-select {
    .vs__dropdown-toggle {
      border-left: 1px solid var(--colour--input-border) !important;
      border-top-left-radius: 0.357rem !important;
      border-bottom-left-radius: 0.357rem !important;
    }

    &.vs--open {
      .vs__dropdown-toggle {
        border-color: var(--colour--primary) !important;
      }

      .vs__dropdown-menu {
        top: 100%;
      }
    }

    &.vs--searching .vs__selected {
      display: none !important;
    }

    .vs__dropdown-option--selected {
      background: inherit !important;
      color: var(--colour--dropdown-item) !important;

      &:hover {
        color: var(--colour--primary) !important;
      }

      &::after {
        display: none !important;
      }
    }
  }
}
</style>
