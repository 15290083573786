<template>
  <prozess-sidebar-modal-wrapper
    id="expanded-entity-overview-widget-form"
    :editing="!!resourceId"
    :visible="visible"
    :form-title="`${$t(titleAction)} ${$t('Widget')}`"
    :loading="loading"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      :id="formId"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <prozess-input
        v-model="form.name"
        :placeholder="$t('Widget Name')"
        icon="GridIcon"
        field="name"
        name="name"
        :error="$hasError('name')"
        :hint="`*${$t('Required')}`"
        @enter="save"
      />
      <ProzessIconSelector
        v-model="form.icon"
        :error="$hasError('icon')"
        required
        :clear-search-on-select="false"
        @enter="save"
      />
      <ProzessStatelessSelect
        :options="fieldOptions"
        :placeholder="$t('Add field')"
        :error="$hasError('fields')"
        :hint="`*${$t('Required')}`"
        @select="handleFieldSelection"
      />
      <EntityOverviewWidgetFormFields
        v-if="selectedFields.length"
        :list.sync="selectedFields"
        @remove="handleFieldRemoval"
      />
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { widgetSchema } from '@/schema/expandedEntityOverview'
import {
  formatUpdatedFields,
  mapFieldForPayload,
} from '@/helpers/expandedEntityOverview'
import ProzessIconSelector from '@/components/shared/ProzessIconSelector.vue'
import expandedEntityOverviewService from '@/services/expandedEntityOverview'
import ProzessStatelessSelect from '@/components/shared/ProzessStatelessSelect.vue'
import EntityOverviewWidgetFormFields from '@/components/EntityOverview/EntityOverviewWidgetFormFields.vue'

const defaultForm = {
  name: null,
  icon: null,
  rowOrder: 0,
  visible: true,
  newFields: [],
  updatedFields: [],
  deletedFields: [],
}

export default {
  name: 'ExpandedEntityOverviewWidgetForm',

  components: {
    ProzessStatelessSelect,
    ProzessIconSelector,
    EntityOverviewWidgetFormFields,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    schemaKey: {
      type: String,
      required: true,
    },

    tableKey: {
      type: String,
      required: true,
    },

    resourceId: {
      type: [Number, String],
      default: null,
    },

    rowOrder: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      formId: 'expandedEntityOverviewWidgetForm',
      saving: false,
      loading: true,
      errors: [],
      form: this.$lodash.cloneDeep(defaultForm),
      fields: [],
      selectedFields: [],
      lastSavedFields: [],
    }
  },

  computed: {
    fieldOptions() {
      return this.fields.filter(
        field => !this.selectedFields.some(
          selection => selection.fieldKey === field.fieldKey,
        ),
      )
    },

    titleAction() {
      return this.resourceId ? 'Edit' : 'Add New'
    },

    entity() {
      return { schemaKey: this.schemaKey, tableKey: this.tableKey }
    },
  },

  watch: {
    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
          this.getFields()

          if (this.resourceId) {
            this.prepareFormData()
          }
        }
      },

      immediate: true,
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    formatAsPayload(selectedFields) {
      if (this.resourceId) {
        const formattedFields = formatUpdatedFields(
          selectedFields,
          this.lastSavedFields,
          this.entity,
        )

        return {
          ...this.form,
          ...formattedFields,
        }
      }

      const { form } = this
      delete form.updatedFields
      delete form.deletedFields

      return {
        ...form,
        rowOrder: this.rowOrder,
        newFields: selectedFields.map((field, rowOrder) => mapFieldForPayload(selectedFields, field, this.entity, rowOrder)),
      }
    },

    async getFields() {
      const { response } = await this.$async(
        expandedEntityOverviewService.getFields({
          schemaKey: this.schemaKey,
          tableKey: this.tableKey,
        }),
      )

      this.fields = response.data
    },

    handleFieldRemoval(e) {
      const index = this.selectedFields.findIndex(
        field => field.fieldKey === e.fieldKey,
      )

      this.selectedFields.splice(index, 1)
    },

    handleFieldSelection(e) {
      this.selectedFields.push(e)
    },

    async prepareFormData() {
      const { response } = await this.$async(
        expandedEntityOverviewService.getOne({
          ...this.entity,
          uuid: this.resourceId,
        }),
      )

      const { data: widget } = response

      this.form = this.$lodash.pick(widget, [
        'name',
        'icon',
        'rowOrder',
        'visible',
      ])

      const selectedFields = widget.fields.map(field => ({
        customField: field.customField,
        fieldKey: field.fieldKey,
        label: field.fieldLabel,
        type: field.fieldType,
      }))

      this.selectedFields = this.$lodash.cloneDeep(selectedFields)
      this.lastSavedFields = this.$lodash.cloneDeep(selectedFields)
    },

    reset() {
      this.errors = []
      this.fields = []
      this.selectedFields = []
      this.lastSavedFields = []
      this.form = this.$lodash.cloneDeep(defaultForm)
      this.loading = false
      this.saving = false
    },

    async save() {
      this.saving = true

      const payload = this.formatAsPayload(this.selectedFields)

      this.errors = await this.yupValidate(widgetSchema, {
        ...payload,
        fields: [...payload.newFields, ...(payload.updatedFields ?? [])],
      })

      if (this.errors.length) {
        this.saving = false
        return
      }

      const params = {
        schemaKey: this.schemaKey,
        tableKey: this.tableKey,
        uuid: this.resourceId,
      }

      const request = this.resourceId
        ? expandedEntityOverviewService.update(params, payload)
        : expandedEntityOverviewService.create(params, payload)

      await this.$async(request)

      this.saving = false

      this.$emit('saved')
      this.close()
    },
  },
}
</script>
