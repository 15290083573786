<template>
  <prozess-field-wrapper
    class="pz__icon-selector"
    :icon="nativeValue || 'FeatherIcon'"
    :error="error"
    :hint="required ? `*${$t('Required')}` : ''"
  >
    <prozess-select
      v-model="nativeValue"
      style="flex: 1"
      label="label"
      :placeholder="$t('Icon')"
      :options="options"
      @keyup.enter.native="$emit('enter')"
    >
      <template #option="{label}">
        <feather-icon :icon="label" class="mr-1" /> {{ $case.title(label) }}
      </template>
      <template #selected-option="{label}">
        {{ $case.title(label) }}
      </template>
    </prozess-select>
  </prozess-field-wrapper>
</template>

<script>
import { FeatherIcons } from '@/constants/app'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },

    error: {
      type: String,
      default: null,
    },

    required: Boolean,
  },

  data() {
    return {
      nativeValue: null,
      options: FeatherIcons,
    }
  },

  watch: {
    value: {
      handler(value) {
        this.nativeValue = value
      },

      immediate: true,
    },

    nativeValue: {
      handler(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss">
.pz__icon-selector {
  .v-select {
    &.vs--open .vs__dropdown-toggle {
      border-color: var(--colour--primary);
      border-bottom-color: var(--colour--primary);
    }

    &.vs--searching .vs__selected {
      display: none !important;
    }
  }
}
</style>
