<template>
    <b-modal
      id="add-widget-modal"
      v-model="open"
      hide-footer
      size="sm"
      centered
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        rounded="sm"
        variant="transparent"
        style="flex: 1"
        class="d-flex flex-column"
      >
      <h1 class="text-center tw-text-lg">{{ $t('Add Widget') }}</h1>
      <!-- <p>{{ JSON.stringify(items.length) }}</p> -->
      <div>
        <h2 class="tw-font-bold tw-text-sm">{{ $t('Widget name') }}</h2>
        <ProzessSelect
            v-model="selectedWidget"
            :options="widgetOption"
            :disabled="allowPreview"
            />
      </div>
      <div class='action-divs' style="justify-content: flex-end; display: flex; padding-top: 10px;padding-bottom: 10px; margin-top: 32px; border: 1px; border-top: 1px solid gainsboro">
            <b-button
               style="margin-right: 8px; "
              @click="closeModal">
              <span class="align-middle">{{ this.$t('Cancel') }}</span>
            </b-button>
            <b-button
            :id="`save-button`"
            :disabled="disableSave()"
            @click="saveButtonPressed()">
            <span class="align-middle">{{ this.$t('Save') }}</span>
          </b-button>
        </div>
    </b-overlay>
</b-modal>
</template>

<script>
import ProzessSelect from '@/@core/components/ProzessSelect.vue'
import { dashboardService } from '@/services/dashboard'
import expandedEntityOverviewService from '@/services/expandedEntityOverview'

export default {
  components: {
    ProzessSelect,
  },
  props: {
    items: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
        schemaKey: '',
        tableKey: '',
        open: false,
        selectedWidget: {},
        widgetOption: [],
        widgets: {},
        widgetUuid: '',
    }
   },
  methods: {
    async openModal({ open, widgetUuid }) {
      this.open = open
      this.widgetUuid = widgetUuid
    },
    async closeModal() {
      this.open = false
    },
    disableSave() {
      return this.selectedWidget == null || this.selectedWidget.key == null
    },
    async saveButtonPressed() {
        const payload = {
            icon: 'DatabaseIcon',
            name: this.selectedWidget.label,
            rowOrder: this.items.length + 1,
            newFields: [{
                customField: false,
                fieldKey: this.selectedWidget.key,
                fieldLabel: '*Widget',
                fieldType: 'WIDGET',
                rowOrder: 0,
                schemaKey: this.schemaKey,
                tableKey: this.tableKey,
            }],
            visible: true,
        }
    const params = {
        schemaKey: this.schemaKey,
        tableKey: this.tableKey,
        uuid: this.widgetUuid,
      }
      const request = this.widgetUuid
        ? expandedEntityOverviewService.update(params, payload)
        : expandedEntityOverviewService.create(params, payload)

      await this.$async(request)

      this.open = false
      this.$emit('saved')
      this.close()
    },
    async fetchWidgets() {
      const { response: widgetResponse } = await this.$async(dashboardService('dashboard').getAllWidgetData())
      this.widgets = widgetResponse.data.pageItems
      this.widgetOption = this.widgets.map(e => ({
            key: e.uuidKey,
            label: e.name,
        }))
    },
  },
  watch: {
    open: {
      handler(newVal) {
        if (newVal) {
            this.selectedWidget = {}
            const [schemaKey, tableKey] = this.$route.params.tab.split('.')
            this.schemaKey = schemaKey
            this.tableKey = tableKey
            this.fetchWidgets()
        }
      },
      immediate: true,
    },
    },
}
</script>
